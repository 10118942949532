import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import FormularioFrase from './Components/FormularioFrase';

function App() {
    const [mostrarFormulario, setMostrarFormulario] = useState(true);
    const [tiempoRestante, setTiempoRestante] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const intervaloRef = useRef(null);

    const iniciarTemporizador = useCallback((tiempoInicial) => {
        if (intervaloRef.current) clearInterval(intervaloRef.current);

        if (tiempoInicial > 0) {
            const finTiempo = Date.now() + tiempoInicial * 1000;
            intervaloRef.current = setInterval(() => {
                const tiempoRestanteActualizado = Math.max(0, Math.round((finTiempo - Date.now()) / 1000));
                console.log('Tiempo restante en el temporizador:', tiempoRestanteActualizado);
                setTiempoRestante(tiempoRestanteActualizado);

                if (tiempoRestanteActualizado <= 0) {
                    clearInterval(intervaloRef.current);
                    setMostrarFormulario(true);
                    localStorage.removeItem('comentarioEnviado');
                }
            }, 1000);
        }
    }, []);

    const checkEstadoComentario = useCallback(async () => {
        try {
            const response = await axios.get('https://www.exp-ss.com/php/Router/RutasFrase.php?accion=obtenerEstadoComentario');
            console.log('Respuesta completa de obtenerEstadoComentario:', response.data);

            if (response.data && response.data.success) {
                const { enviado, tiempoRestante } = response.data.data;

                console.log('Valores extraídos - Enviado:', enviado, 'Tiempo Restante:', tiempoRestante);

                const tiempoRestanteNumero = Number(tiempoRestante);
                console.log('Tiempo restante convertido a número:', tiempoRestanteNumero);

                if (typeof tiempoRestanteNumero === 'number' && tiempoRestanteNumero > 0) {
                    setTiempoRestante(tiempoRestanteNumero);

                    if (enviado) {
                        setMostrarFormulario(false);
                        localStorage.setItem('comentarioEnviado', JSON.stringify({
                            enviado: true,
                            tiempoRestante: tiempoRestanteNumero
                        }));
                        iniciarTemporizador(tiempoRestanteNumero);
                    } else {
                        setMostrarFormulario(true);
                        localStorage.removeItem('comentarioEnviado');
                    }
                } else {
                    setMostrarFormulario(true);
                    localStorage.removeItem('comentarioEnviado');
                }
            } else {
                setMostrarFormulario(true);
                localStorage.removeItem('comentarioEnviado');
            }
        } catch (error) {
            console.error('Error al obtener el estado del comentario:', error);
            setMostrarFormulario(true);
            localStorage.removeItem('comentarioEnviado');
        }
    }, [iniciarTemporizador]);

    useEffect(() => {
        checkEstadoComentario();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            if (intervaloRef.current) clearInterval(intervaloRef.current);
        };
    }, [checkEstadoComentario]);

    const handleSuccess = async () => {
        try {
            // Llamar al backend para obtener el estado actualizado justo después de enviar el formulario
            const response = await axios.get('https://www.exp-ss.com/php/Router/RutasFrase.php?accion=obtenerEstadoComentario');
            if (response.data && response.data.success) {
                const { enviado, tiempoRestante } = response.data.data;
                const tiempoRestanteNumero = Number(tiempoRestante);

                if (enviado && tiempoRestanteNumero > 0) {
                    setMostrarFormulario(false);
                    setTiempoRestante(tiempoRestanteNumero);
                    iniciarTemporizador(tiempoRestanteNumero);
                    localStorage.setItem('comentarioEnviado', JSON.stringify({
                        enviado: true,
                        tiempoRestante: tiempoRestanteNumero
                    }));
                }
            }
        } catch (error) {
            console.error('Error al obtener el estado del comentario después de enviar:', error);
            setMostrarFormulario(true);
        }
    };

    if (!isMobile) {
        return (
            <div className='flex flex-col min-h-screen items-center justify-center'>
                <div className='bg-black h-5'></div>
                <div className='bg-black py-2 uppercase flex justify-center w-full'>
                    <h2 className="text-4xl text-center w-3/12 font-custom text-white">
                        Para vivir la experiencia, visítanos desde tu smartphone.
                    </h2>
                </div>
                <div className='bg-black h-5'></div>
            </div>
        );
    }

    return (
        <div className='flex flex-col min-h-screen'>
            <div className='bg-black h-5'></div>
            <div className="mt-3 md:mt-6 md:ml-36 ml-6 md:mb-0 mb-6 py-5">
                        <img
                            src="/Burbujas2.png"
                            alt="Imagen flotante"
                            className="w-13 h-13"
                        />
                    </div>
            <div className='flex flex-1 items-center justify-center w-full'>
                <div className='bg-white max-w-lg'>
                    <div className='bg-black w-full mb-4 py-2 px-8'>
                        <h1 className="text-3xl text-center uppercase font-custom text-white">
                            escribe un mensaje para tu besties y hazle saber cuanto la quieres.
                        </h1>
                    </div>
                    <div className='flex flex-col h-full p-4'>
                        <FormularioFrase
                            onSuccess={handleSuccess}
                            mostrarFormulario={mostrarFormulario}
                            tiempoRestante={tiempoRestante}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-black h-5'></div>
        </div>
    );
}

export default App;
