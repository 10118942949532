import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PalabrasProhibidas from './PalabrasProhibidas';

function FormularioFrase({ onSuccess }) {
    const [texto, setTexto] = useState('');
    const [namefrom, setNamefrom] = useState('');
    const [namefor, setNamefor] = useState('');
    const [contieneError, setContieneError] = useState(false);
    const [mensajeAdvertencia, setMensajeAdvertencia] = useState('');
    const [tiempoRestante, setTiempoRestante] = useState(0);
    const [contadorIniciado, setContadorIniciado] = useState(false);

    const formularioEnviado = () => localStorage.getItem('formularioEnviado') === 'true';

    const obtenerEstadoComentario = async () => {
        try {
            const response = await axios.get(`https://www.exp-ss.com/php/Router/RutasFrase.php?accion=obtenerTiempoRestante&timestamp=${Date.now()}`);
            const { success, tiempoRestante } = response.data;
            const tiempoRestanteNumero = Number(tiempoRestante);

            if (success) {
                if (tiempoRestanteNumero > 0) {
                    setTiempoRestante(tiempoRestanteNumero);
                    setContadorIniciado(true);
                } else {
                    setTiempoRestante(0);
                    setContadorIniciado(false);
                }
            }
        } catch (error) {
            console.error('Error al obtener el estado del comentario:', error);
        }
    };

    useEffect(() => {
        if (formularioEnviado()) {
            obtenerEstadoComentario();
        }
    }, []);

    useEffect(() => {
        if (contadorIniciado && tiempoRestante > 0) {
            const interval = setInterval(() => {
                setTiempoRestante(prevTiempo => Math.max(0, prevTiempo - 1));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [contadorIniciado, tiempoRestante]);

    const formatearTiempoRestante = (segundos) => {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const segundosRestantes = segundos % 60;

        if (horas > 0) {
            return `${horas} horas, ${minutos} minutos y ${segundosRestantes} segundos`;
        } else if (minutos > 0) {
            return `${minutos} minutos y ${segundosRestantes} segundos`;
        } else {
            return `${segundosRestantes} segundos`;
        }
    };

    const contarCaracteres = (texto) => texto.length;

    const soloLetrasYEspacios = (texto) => {
        if (texto === '') return true;
        const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/;
        return regex.test(texto);
    };

    const detectarPalabraInapropiada = (texto) => {
        return PalabrasProhibidas.some(palabra => {
            const regex = new RegExp(`\\b${palabra}\\b`, 'gi');
            return regex.test(texto);
        });
    };

    const manejarCambioTexto = (e) => {
        const nuevoTexto = e.target.value.slice(0, 60); // Límite de 60 caracteres

        if (nuevoTexto === '') {
            setTexto('');
            setContieneError(false);
            setMensajeAdvertencia('');
            return;
        }

        if (soloLetrasYEspacios(nuevoTexto)) {
            setMensajeAdvertencia('');
            setTexto(nuevoTexto);

            const palabraInapropiadaDetectada = detectarPalabraInapropiada(nuevoTexto);

            if (palabraInapropiadaDetectada) {
                setContieneError(true);
                setMensajeAdvertencia('Evita usar lenguaje inapropiado.');
            } else {
                setContieneError(false);
                setMensajeAdvertencia('');
            }
        } else {
            setContieneError(true);
            setMensajeAdvertencia('Evita usar números y caracteres especiales.');
        }
    };

    const manejarCambioNamefrom = (e) => {
        const nuevoTexto = e.target.value.slice(0, 10); // Límite de 10 caracteres

        if (soloLetrasYEspacios(nuevoTexto)) {
            setNamefrom(nuevoTexto);
            setContieneError(false);
            setMensajeAdvertencia('');
        } else {
            setMensajeAdvertencia('Evita usar números y caracteres especiales.');
            setContieneError(true);
        }
    };

    const manejarCambioNamefor = (e) => {
        const nuevoTexto = e.target.value.slice(0, 10); // Límite de 10 caracteres

        if (soloLetrasYEspacios(nuevoTexto)) {
            setNamefor(nuevoTexto);
            setContieneError(false);
            setMensajeAdvertencia('');
        } else {
            setMensajeAdvertencia('Evita usar números y caracteres especiales.');
            setContieneError(true);
        }
    };

    const manejarEnvio = async (e) => {
        e.preventDefault();

        if (contieneError || tiempoRestante > 0) {
            return;
        }

        try {
            await axios.post(
                `https://www.exp-ss.com/php/Router/RutasFrase.php?accion=crearComentario`,
                {
                    namefrom,
                    namefor,
                    message: texto
                },
                { headers: { 'Content-Type': 'application/json' } }
            );

            setTexto('');
            setNamefrom('');
            setNamefor('');
            setTiempoRestante(0);
            localStorage.setItem('formularioEnviado', 'true');
            obtenerEstadoComentario();
            onSuccess();
        } catch (error) {
            console.error('Error al enviar el comentario:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="w-full p-6">
            <form onSubmit={manejarEnvio} className="w-full flex flex-col">
                <div className='relative flex items-center mb-11'>
                    <label htmlFor="namefrom" className="block text-black text-2xl font-custom uppercase w-2/12">De:</label>
                    <input
                        type="text"
                        id="namefrom"
                        value={namefrom}
                        onChange={manejarCambioNamefrom}
                        className="border-2 border-black p-2 w-10/12 focus:outline-none h-7 focus:ring-4 focus:ring-black focus:ring-opacity-30 transition-shadow duration-300 ease-in-out"
                        required
                    />
                    <span className="absolute bottom-1 right-1 font-custom text-xs text-black">{`${contarCaracteres(namefrom)}/10`}</span>
                </div>
                <div className='relative flex items-center mb-11'>
                    <label htmlFor="namefor" className="block text-black text-2xl font-custom uppercase w-2/12">Para:</label>
                    <input
                        type="text"
                        id="namefor"
                        value={namefor}
                        onChange={manejarCambioNamefor}
                        className="border-2 border-black p-2 w-10/12 h-7 focus:outline-none focus:ring-4 focus:ring-black focus:ring-opacity-30 transition-shadow duration-300 ease-in-out"
                        required
                    />
                    <span className="absolute bottom-1 right-1 text-xs font-custom text-black">{`${contarCaracteres(namefor)}/10`}</span>
                </div>
                <div className='relative mb-11'>
                    <label htmlFor="texto" className="block text-black text-2xl font-custom uppercase">Mensaje:</label>
                    <textarea
                        id="texto"
                        value={texto}
                        onChange={manejarCambioTexto}
                        className="border-2 border-black p-4 w-full focus:outline-none focus:ring-4 focus:ring-black focus:ring-opacity-30 transition-shadow duration-300 ease-in-out"
                        rows="4"
                    ></textarea>
                    <span className="absolute bottom-2 right-1 text-xs font-custom text-black">{`${contarCaracteres(texto)}/60`}</span>

                    {mensajeAdvertencia && (
                        <div className="text-red-500 font-custom text-sm mt-2">{mensajeAdvertencia}</div>
                    )}
                </div>
                {tiempoRestante > 0 && (
                    <div className="text-black text-center font-custom mt-4">
                        Debes esperar {formatearTiempoRestante(tiempoRestante)} para enviar un nuevo comentario.
                    </div>
                )}
                <div className='flex flex-col items-center'>
                    <div className='w-full flex justify-center'>
                        <button
                            type="submit"
                            className="bg-black text-center text-2xl text-white font-custom py-2 px-8 uppercase mt-4 mb-5"
                            disabled={tiempoRestante > 0 || contieneError}
                        >
                            Enviar mensaje
                        </button>
                    </div>
                </div>
                <div className="flex justify-end">
                    <img
                        src="/bURBUJAS.png"
                        alt="Imagen flotante"
                        className="w-13 h-13"
                    />
                </div>
            </form>
        </div>
    );
}

export default FormularioFrase;
